import { render, staticRenderFns } from "./addColumn.vue?vue&type=template&id=934bdf62&scoped=true&"
import script from "./addColumn.vue?vue&type=script&lang=js&"
export * from "./addColumn.vue?vue&type=script&lang=js&"
import style0 from "./addColumn.vue?vue&type=style&index=0&id=934bdf62&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/_vue-loader@15.9.6@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "934bdf62",
  null
  
)

export default component.exports