/* 
 * @Author: 曹俊杰 
 * @Date: 2020-11-03 20:21:33
 * @Module: 发布动态
 */
<template>
  <div class="publish-box">
    <div class="publish-header">
      <div class="publish-header-content">
        <img src="@/static/img/laboratory/blacklogo.png"
             alt="logo"
             @click="goSy"
             class="publish-header-content-l" />
        <div class="publish-header-content-r">
          <div class="publish-header-content-r-menu">
            <div class="publish-header-content-r-menu-icon hover"
                 @click="$router.go(-1)">
              <i class="el-icon-back"></i>
            </div>
            <el-dropdown @command="menuClick">
              <div class="publish-header-content-r-menu-text hover">
                {{ avtive.title }}
                <i class="el-icon-caret-bottom"
                   style="color:#999"></i>
              </div>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item v-for="item in menuList"
                                  :key="item.key"
                                  v-show="item.key !== avtive.key"
                                  :command="item.key">{{ item.title }}</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
          <div class="publish-header-content-r-btn hover"
               @click="onPublishClick"
               v-show="show_btn">
            发布
          </div>
        </div>
      </div>
    </div>
    <div class="publish-content">
      <publish-image v-show="avtive.key == 'image'"
                     ref="image"></publish-image>
      <publish-article v-show="avtive.key == 'article'"
                       ref="article"
                       @showBtn="showBtn"></publish-article>
      <publish-video v-show="avtive.key == 'video'"
                     ref="video"
                     @showBtn="showBtn"></publish-video>
      <publishShortVideo v-show="avtive.key == 'shortVideo'"
                         ref="shortVideo">

      </publishShortVideo>
    </div>
  </div>
</template>
<script>
import publishImage from "./module/publishImage";
import publishArticle from "./module/publishArticle";
import publishVideo from "./module/publishVideo";
import publishShortVideo from "./module/publishShortVideo";
export default {
  components: {
    publishImage,
    publishArticle,
    publishVideo,
    publishShortVideo
  },
  data () {
    return {
      menuList: [
        {
          title: "发微头条",
          key: "image"
        },
        {
          title: "发布文章",
          key: "article"
        },
        {
          title: "发布视频",
          key: "video"
        },
        {
          title: "发小视频",
          key: "shortVideo"
        }
      ],
      avtive: {
        title: "发微头条",
        key: "image"
      },
      show_btn: true
    };
  },
  mounted () { },
  methods: {
    goSy () {
      this.$router.push("/homepage");
    },
    menuClick (key) {
      this.avtive = this.menuList.find(item => item.key === key);
    },
    onPublishClick () {
      this.$refs[this.avtive.key].onPublish();
    },
    showBtn (res) {
      this.show_btn = res;
    }
  }
};
</script>
<style lang='scss' scoped>
.logo {
  width: 156px;
}
.publish-box {
  background: #f7f7f7;
  min-height: 100vh;
}
.publish {
  &-header {
    height: 60px;
    background: #fff;
    box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.06);
    &-content {
      width: 1240px;
      margin: 0 auto;
      height: 100%;
      display: flex;
      align-items: center;
      &-l {
        width: 156px;
        // position: absolute;
        // left: -216px;
        height: 36px;
      }
      &-r {
        width: 1040px;
        margin: 0 auto;
        margin-left: 24px;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        &-menu {
          display: flex;
          &-icon {
            width: 24px;
            height: 24px;
            background: #f7f7f7;
            border: 1px solid #e3e3e3;
            border-radius: 12px;
            text-align: center;
            line-height: 24px;
            color: #999;
          }
          &-text {
            line-height: 24px;
            margin-left: 20px;
            font-size: 16px;
            color: #333333;
          }
        }
        &-btn {
          width: 82px;
          line-height: 32px;
          background: #df2a29;
          border-radius: 2px;
          text-align: center;
          color: #fff;
        }
      }
    }
  }
  &-content {
    width: 880px;
    min-height: 500px;
    margin: 0 auto;
    margin-top: 20px;
  }
}
</style>