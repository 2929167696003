/* 
 * @Author: 曹俊杰 
 * @Date: 2020-12-30 14:00:34
 * @Module: 发布小视频
 */
<template>
  <div class="publishShortVideo-box">
    <div class="publishShortVideo-content">
      <div v-show="fileList.length !==0"
           class="publishShortVideo-content-success">视频上传成功</div>
      <el-upload class="upload-demo"
                 :action="action"
                 :limit="1"
                 :on-remove="handleRemove"
                 :data="dataObj"
                 :on-preview="handlePreview"
                 :on-success="success"
                 :before-upload="upload">
        <div class="publishShortVideo-content-btn"
             v-show="fileList.length==0">上传视频</div>
      </el-upload>
      <div class="publishShortVideo-content-suggest"
           v-show="fileList.length==0">建议分辨率不低于720PZ</div>
      <el-dialog visible
                 @close="dialogVisible=false"
                 v-if="dialogVisible">
        <video :src="action+path"
               controls
               style="width:100%">
        </video>
      </el-dialog>
    </div>
    <div class="publishShortVideo-form"
         v-show="fileList.length !==0">
      <el-form ref="formData"
               label-width="100px"
               :model="formData"
               :rules="rules">
        <el-form-item label="视频内容:"
                      prop="content">
          <Mentionable :keys="['@']"
                       :items="items"
                       insert-space
                       ref="at"
                       offset="6">
            <el-input v-model="formData.content"
                      type="textarea"
                      placeholder="说点什么吧..."
                      :autosize="{ minRows: 4,maxRows:9 }"
                      maxlength="2000"
                      show-word-limit></el-input>
          </Mentionable>

        </el-form-item>
        <el-form-item label="封面:"
                      prop="cover">
          <from-cover v-model="formData.cover"
                      :videoaction="action"
                      hint="封面格式JPG、JPEG、PNG"
                      :videopath="path" />
        </el-form-item>
        <el-form-item label="定时发送:"
                      v-show="administrator">
          <datePicker ref="datePicker"
                      style="margin:0px;" />
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>
 <script>
import fromCover from "./fromCover"
import datePicker from "./datePicker"
import administrator from "@/mixins/administrator"
import Mentionable from '@/components/mentionable'
import userinfo from "@/mixins/userinfo"
export default {
  components: {
    fromCover,
    datePicker,
    Mentionable
  },
  data () {
    return {
      column_id: null,
      is_original: false,
      columnListOption: [],
      dataObj: {
        success_action_status: '200',
      },
      fileList: [],
      path: "",
      action: '',
      dialogVisible: false,
      formData: {
        content: '',
        cover: ''
      },
      rules: {
        cover: [
          { required: true, message: '请选择封面', trigger: 'blur' },
        ],
        content: [
          { required: true, message: '请输入视频内容', trigger: 'blur' },
        ]
      },
      followedList: [],
    };
  },
  mixins: [administrator, userinfo],
  created () {
  },
  mounted () {
    this._getAliyun()
    this._get_followedList()
  },
  computed: {
    //@ 的下拉菜单
    items () {
      if (this.followedList.length)
        return this.followedList.map(item => ({ value: item.nick_name, firstName: item.uuid, user_id: item.user_id }))
      else
        return []
    }
  },
  methods: {
    //获取关注列表
    async _get_followedList () {
      const { data, status } = await this.$api.followedListApi({ uuid: this.userInfo.uuid })
      if (status === 200)
        this.followedList = data
    },
    onPublish () {   //点击发布按钮
      console.log('发布视频')
      this.$refs.formData.validate((valid) => {
        if (valid) {
          const mentions = this.get_mentions()
          let { content, cover } = this.formData
          // if (cover.indexOf("?x-oss-process=video/snapshot,t_0,f_jpg,m_fast") != -1) {
          //   cover = null
          // }
          this._set_data({
            content,
            cover,
            media: this.path,
            mentions
          })
        } else {
          return false;
        }
      });

    },
    async _getAliyun () {
      const { data: {
        policy,
        signature,
        accessid,
        host,
        dir
      } } = await this.$api.getAliyun({ type: 'video' })
      this.action = host
      Object.assign(this.dataObj, {
        policy,
        signature,
        dir,
        OSSAccessKeyId: accessid
      })
    },
    handleRemove (file, fileList) {  //文件列表移除文件时的钩子
      console.log(file, fileList)
      this.fileList = []
    },
    getFileName (filename) {    // 设置文件名字
      let getSuffix = (filename) => {
        let pos = filename.lastIndexOf('.');
        let suffix = '';
        if (pos != -1) {
          suffix = filename.substring(pos);
        }
        return suffix;
      };
      return (
        new Date().getTime() +
        Math.random()
          .toString(36)
          .substring(3, 20) +
        getSuffix(filename)
      );
    },
    upload (file) {   //文件上传前的回调
      console.log(file)
      console.log(file.type.split('/')[0])
      if (file.type.split('/')[0] !== 'video') {
        this.$message.error("请选择正确的格式。")
        return false
      }
      if (file.size > 1024 * 1024 * 200) {
        this.$message.error("视频最大支持二百兆。")
        return false
      }
      const filename = this.getFileName(file.name)
      const copyFile = new File([file], `${filename}`)
      Object.assign(this.dataObj, {
        key: `${this.dataObj.dir}${filename}`
      })
      this.path = `${this.dataObj.dir}${filename}`
      return copyFile
    },
    success (response, file, fileList) {   //上传成功
      console.log(response, file, fileList)
      this.formData.cover = this.path + "?x-oss-process=video/snapshot,t_0,f_jpg,m_fast"
      this.fileList = fileList
    },
    handlePreview () {
      this.dialogVisible = true
    },
    async _set_data (data) {   //请求发布
      if (this.administrator || this.$refs.datePicker.value) {
        data.published_at = this.$refs.datePicker.value
      }
      const { info, status } = await this.$api.publishShortVideoApi(data)
      if (status == 200) {
        this.$router.go(-1)
        this.$message.success(info)
      } else {
        this.$message.error(info)
      }
    },
    //  获取At的人
    get_mentions () {
      function getExecStrs (str) {
        var reg = /@(.+?)\s/g
        var list = []
        var result = null
        do {
          result = reg.exec(str)
          result && list.push(result[1])
        } while (result)
        return list
      }
      var str = this.formData.content
      let atUserList = getExecStrs(str)
      console.log(this.$refs.at)
      let mentions = []
      atUserList.forEach(item => {
        this.followedList.forEach(itm => {
          if (item == itm.nick_name) {
            mentions.push({
              user_id: itm.user_id,
              nick_name: item,
              uuid: itm.uuid
            })
          }
        })
      })
      return mentions
    },
  },

};
 </script>
 <style lang='scss' scoped>
.publishShortVideo-box {
  padding-bottom: 20px;
  // background: #f66;
}
.active {
  display: flex;
  div {
    margin-right: 30px;
  }
}
.publishShortVideo-content {
  margin-top: 20px;
  height: 198px;
  background: #ffffff;
  box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  padding-top: 60px;
  padding-bottom: 41px;
  display: flex;
  flex-direction: column;
  align-items: center;
  &-btn {
    width: 140px;
    line-height: 40px;
    background: #df2a29;
    border-radius: 4px;
    color: #fff;
    text-align: center;
    margin: 0 auto;
    font-size: 14px;
  }
  &-suggest {
    text-align: center;
    margin-top: 27px;
    font-size: 14px;
    color: #666;
  }
  &-success {
    font-size: 20px;
    color: #df2a29;
  }
}
.publishShortVideo-form {
  margin-top: 16px;
  width: 880px;
  // height: 436px;
  background: #ffffff;
  box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  padding: 40px 150px 40px 50px;
}
.column-box {
  margin-top: 16px;
  height: 178px;
  background: #ffffff;
  box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  padding: 40px 50px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  &-item {
    display: flex;
    align-items: center;
    height: 28px;
    &-l {
      font-size: 16px;
      color: #333;
    }
    &-r {
      margin-left: 42px;
    }
  }
}
</style>
