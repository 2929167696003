/* 
 * @Author: 曹俊杰 
 * @Date: 2020-11-04 15:46:04
 * @Module: 发布文章
 */
 <template>
  <div class="publishArticle-box">
    <div class="active">
      <div v-for="item in activeList"
           :key="item.key"
           :style="item.key==active?'color:#333;':'color: #999;'"
           @click="active=item.key"
           class="hover">{{item.title}}</div>
    </div>
    <div v-show="active =='publish'">
      <div class="publishArticle-content editor_wrap">

        <div class="publishArticle-title">
          <input type="text"
                 v-model="title"
                 maxlength="25"
                 show-word-limit
                 placeholder="请输入文章标题（5-25个字）">
        </div>
        <quill-editor v-model="content"
                      class="editor"
                      ref="myQuillEditor"
                      :options="editorOption">
        </quill-editor>
      </div>
      <div class="column-box">
        <el-form label-width="100px">
          <el-form-item label="原创设置:">
            <el-checkbox v-model="is_original">是否为原创</el-checkbox>
          </el-form-item>
          <el-form-item label="专栏设置:">
            <el-select v-model="column_id"
                       size="mini"
                       placeholder="请选择">
              <el-option v-for="item in columnListOption"
                         :key="item.id"
                         :label="item.title"
                         :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="定时发送:"
                        v-show="administrator">
            <datePicker ref="datePicker"
                        style="margin:0px;" />
          </el-form-item>
        </el-form>
      </div>
    </div>
    <add-column v-show="active =='column'"
                type="1"
                @onSuccess="columnSuccess" />
  </div>
</template>
 <script>
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'

import { quillEditor, Quill } from 'vue-quill-editor'
import { ImageExtend, QuillWatch } from 'quill-image-extend-module'
import ImageResize from '@taoqf/quill-image-resize-module';
import addColumn from './addColumn'
Quill.register('modules/ImageExtend', ImageExtend)
Quill.register('modules/ImageResize', ImageResize)
const toolbarOptions = [
  ["bold", "italic", "underline", "strike"], // 加粗 斜体 下划线 删除线
  ["blockquote", "code-block"], // 引用  代码块
  // [{ header: 1 }, { header: 2 }], // 1、2 级标题
  [{ list: "ordered" }, { list: "bullet" }], // 有序、无序列表
  [{ script: "sub" }, { script: "super" }], // 上标/下标
  [{ indent: "-1" }, { indent: "+1" }], // 缩进  2
  [{
    color: [
      '#ffffff', '#ffd7d5', '#ffdaa9', '#fffed5', '#d4fa00', '#73fcd6', '#a5c8ff', '#ffacd5', '#ff7faa',
      '#d6d6d6', '#ffacaa', '#ffb995', '#fffb00', '#73fa79', '#00fcff', '#78acfe', '#d84fa9', '#ff4f79',
      '#b2b2b2', '#d7aba9', '#ff6827', '#ffda51', '#00d100', '#00d5ff', '#0080ff', '#ac39ff', '#ff2941',
      '#888888', '#7a4442', '#ff4c00', '#ffa900', '#3da742', '#3daad6', '#0052ff', '#7a4fd6', '#d92142',
      '#000000', '#7b0c00', '#ff0000', '#d6a841', '#407600', '#007aaa', '#021eaa', '#797baa', '#ab1942'
    ]
  }, {
    background: [
      '#ffffff', '#ffd7d5', '#ffdaa9', '#fffed5', '#d4fa00', '#73fcd6', '#a5c8ff', '#ffacd5', '#ff7faa',
      '#d6d6d6', '#ffacaa', '#ffb995', '#fffb00', '#73fa79', '#00fcff', '#78acfe', '#d84fa9', '#ff4f79',
      '#b2b2b2', '#d7aba9', '#ff6827', '#ffda51', '#00d100', '#00d5ff', '#0080ff', '#ac39ff', '#ff2941',
      '#888888', '#7a4442', '#ff4c00', '#ffa900', '#3da742', '#3daad6', '#0052ff', '#7a4fd6', '#d92142',
      '#000000', '#7b0c00', '#ff0000', '#d6a841', '#407600', '#007aaa', '#021eaa', '#797baa', '#ab1942'
    ]
  }], // 字体颜色、字体背景颜色
  [{ size: ["small", false, "large", "huge"] }], // 字体大小 2
  [{ header: [1, 2, 3, 4, 5, 6, false] }], // 标题
  [{ font: [] }], // 字体种类 2
  [{ 'direction': 'rtl' }],               // 文本方向  2
  [{ align: [] }], // 对齐方式 2
  ["clean"], // 清除文本格式
  ["link", "image", "video"] // 链接、图片、视频
];
import datePicker from "./datePicker"
import administrator from "@/mixins/administrator"
export default {
  components: {
    quillEditor,
    addColumn,
    datePicker
  },
  data () {
    return {
      is_original: false,
      column_id: null,
      title: '',
      active: 'publish',
      activeList: [{
        title: '发布文章',
        key: 'publish'
      }, {
        title: '创建专栏',
        key: 'column'
      }],
      columnListOption: [],
      content: "",
      // 富文本框参数设置
      editorOption: {
        placeholder: "您想说点什么？",
        modules: {
          ImageResize: {
            modules: ['Resize', 'DisplaySize', 'Toolbar']
          },
          ImageExtend: {
            loading: true,
            name: 'media',
            action: "/api/moment/article/upload-image",
            headers: (xhr) => {
              xhr.setRequestHeader('Authorization', 'Bearer ' + this.$store.state.user.userInfo.token)
            },  // 可选参数 设置请求头部
            response: (res) => {
              console.log(res)
              return res.data.path
            }
          },
          toolbar: {
            container: toolbarOptions,
            handlers: {
              'image': function () {
                QuillWatch.emit(this.quill.id)
              }
            }
          }
        }
      }
    };
  },
  mixins: [administrator],
  mounted () {
    this._get_columnListOption()
  },
  methods: {
    onPublish () {
      console.log('发布文章')
      const {
        is_original,
        column_id,
        title,
        content
      } = this
      this._set_data({
        is_original: is_original ? 1 : 0,
        column_id,
        title,
        content
      })
    },
    // 获取专栏列表下拉菜单
    async _get_columnListOption () {
      const { status, data } = await this.$api.columnListOptionApi({ type: 1 })
      if (status == 200) {
        this.columnListOption = data
      }
    },
    // 请求发布
    async _set_data (data) {
      if (this.administrator || this.$refs.datePicker.value) {
        data.published_at = this.$refs.datePicker.value
      }
      const { info, status } = await this.$api.publishArticleApi(data)
      if (status == 200) {
        this.$router.go(-1)
        this.$message.success(info)
      } else {
        this.$message.error(info)
      }
    },
    columnSuccess () {   //专栏创建成功     刷新专栏下拉列表  跳回发布页
      this._get_columnListOption()
      this.active = 'publish'
    }
  },
  watch: {
    active: {
      handler: function (val) {
        if (val == 'publish') {
          this.$emit('showBtn', true)
        } else {
          this.$emit('showBtn', false)
        }
      },
      // 深度观察监听
      deep: true,
      immediate: true
    }
  },
};
 </script>
 <style lang='scss' scoped>
.publishArticle-box {
  padding-bottom: 20px;
}
.active {
  display: flex;
  div {
    margin-right: 30px;
  }
}
.publishArticle-content {
  margin-top: 20px;
  min-height: 520px;
  background: #ffffff;
  box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  padding: 40px 50px;
}
.publishArticle-title {
  font-size: 24px;
  input {
    width: 100%;
  }
  input::placeholder {
    font-size: 24px;
    color: #999999;
  }
}
.column-box {
  margin-top: 16px;
  min-height: 178px;
  background: #ffffff;
  box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  padding: 40px 50px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  &-item {
    display: flex;
    align-items: center;
    height: 28px;
    &-l {
      font-size: 16px;
      color: #333;
    }
    &-r {
      margin-left: 42px;
    }
  }
}
.editor_wrap /deep/ .avatar-uploader {
  display: none;
}
.editor_wrap /deep/ .editor {
  margin-top: 22px;
  line-height: normal !important;
  height: 340px;
  margin-bottom: 60px;
}
.editor_wrap /deep/ .editor .ql-bubble .ql-editor a {
  color: #136ec2;
}
.editor_wrap /deep/ .editor img {
  max-width: 720px;
  margin: 10px;
}
.editor_wrap /deep/ .ql-snow .ql-color-picker .ql-picker-options {
  padding: 3px 5px;
  width: 192px;
}
.editor_wrap /deep/ .ql-snow .ql-tooltip[data-mode="link"]::before {
  content: "请输入链接地址:";
}
.editor_wrap /deep/ .ql-snow .ql-tooltip.ql-editing a.ql-action::after {
  border-right: 0px;
  content: "保存";
  padding-right: 0px;
}

.editor_wrap /deep/ .ql-snow .ql-tooltip[data-mode="video"]::before {
  content: "请输入视频地址:";
}

.editor_wrap /deep/ .ql-snow .ql-picker.ql-size .ql-picker-label::before,
.editor_wrap /deep/ .ql-snow .ql-picker.ql-size .ql-picker-item::before {
  content: "14px";
}
.editor_wrap
  /deep/
  .ql-snow
  .ql-picker.ql-size
  .ql-picker-label[data-value="small"]::before,
.editor_wrap
  /deep/
  .ql-snow
  .ql-picker.ql-size
  .ql-picker-item[data-value="small"]::before {
  content: "10px";
}
.editor_wrap
  /deep/
  .ql-snow
  .ql-picker.ql-size
  .ql-picker-label[data-value="large"]::before,
.editor_wrap
  /deep/
  .ql-snow
  .ql-picker.ql-size
  .ql-picker-item[data-value="large"]::before {
  content: "18px";
}
.editor_wrap
  /deep/
  .ql-snow
  .ql-picker.ql-size
  .ql-picker-label[data-value="huge"]::before,
.editor_wrap
  /deep/
  .ql-snow
  .ql-picker.ql-size
  .ql-picker-item[data-value="huge"]::before {
  content: "32px";
}

.editor_wrap /deep/ .ql-snow .ql-picker.ql-header .ql-picker-label::before,
.editor_wrap /deep/ .ql-snow .ql-picker.ql-header .ql-picker-item::before {
  content: "文本";
}
.editor_wrap
  /deep/
  .ql-snow
  .ql-picker.ql-header
  .ql-picker-label[data-value="1"]::before,
.editor_wrap
  /deep/
  .ql-snow
  .ql-picker.ql-header
  .ql-picker-item[data-value="1"]::before {
  content: "标题1";
}
.editor_wrap
  /deep/
  .ql-snow
  .ql-picker.ql-header
  .ql-picker-label[data-value="2"]::before,
.editor_wrap
  /deep/
  .ql-snow
  .ql-picker.ql-header
  .ql-picker-item[data-value="2"]::before {
  content: "标题2";
}
.editor_wrap
  /deep/
  .ql-snow
  .ql-picker.ql-header
  .ql-picker-label[data-value="3"]::before,
.editor_wrap
  /deep/
  .ql-snow
  .ql-picker.ql-header
  .ql-picker-item[data-value="3"]::before {
  content: "标题3";
}
.editor_wrap
  /deep/
  .ql-snow
  .ql-picker.ql-header
  .ql-picker-label[data-value="4"]::before,
.editor_wrap
  /deep/
  .ql-snow
  .ql-picker.ql-header
  .ql-picker-item[data-value="4"]::before {
  content: "标题4";
}
.editor_wrap
  /deep/
  .ql-snow
  .ql-picker.ql-header
  .ql-picker-label[data-value="5"]::before,
.editor_wrap
  /deep/
  .ql-snow
  .ql-picker.ql-header
  .ql-picker-item[data-value="5"]::before {
  content: "标题5";
}
.editor_wrap
  /deep/
  .ql-snow
  .ql-picker.ql-header
  .ql-picker-label[data-value="6"]::before,
.editor_wrap
  /deep/
  .ql-snow
  .ql-picker.ql-header
  .ql-picker-item[data-value="6"]::before {
  content: "标题6";
}

.editor_wrap /deep/ .ql-snow .ql-picker.ql-font .ql-picker-label::before,
.editor_wrap /deep/ .ql-snow .ql-picker.ql-font .ql-picker-item::before {
  content: "标准字体";
}
.editor_wrap
  /deep/
  .ql-snow
  .ql-picker.ql-font
  .ql-picker-label[data-value="serif"]::before,
.editor_wrap
  /deep/
  .ql-snow
  .ql-picker.ql-font
  .ql-picker-item[data-value="serif"]::before {
  content: "衬线字体";
}
.editor_wrap
  /deep/
  .ql-snow
  .ql-picker.ql-font
  .ql-picker-label[data-value="monospace"]::before,
.editor_wrap
  /deep/
  .ql-snow
  .ql-picker.ql-font
  .ql-picker-item[data-value="monospace"]::before {
  content: "等宽字体";
}
.editor_wrap /deep/ .ql-toolbar {
  border: none;
  padding: 0px;
}
.editor_wrap /deep/ .ql-container {
  border: none;
  // padding: 0px;
}
.editor_wrap /deep/ .ql-editor {
  padding: 7px;
}
</style>