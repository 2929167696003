import { mapState } from "vuex";
export default {
	data () {
		return {
		}
	},
	computed: {
		...mapState({
			administrator: (state) => state.user.administrator,
		})
	}
}