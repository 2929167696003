/* 
 * @Author: 曹俊杰 
 * @Date: 2020-12-30 16:12:07
 * @Module: 选择推送时间
 */
 <template>
  <div v-if="administrator">
    <el-date-picker v-model="value"
                    type="datetime"
                    value-format="yyyy-MM-dd HH:mm:ss"
                    placeholder="选择定时发送日期和时间">
    </el-date-picker>
  </div>
</template>
 <script>
import administrator from "@/mixins/administrator"
export default {
  components: {},
  data () {
    return {
      value: ''
    };
  },
  mixins: [administrator],
  computed: {
  },
  mounted () {

  },
  methods: {},
};
 </script>
 <style lang='scss' scoped>
</style>