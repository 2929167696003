/* 
 * @Author: 曹俊杰 
 * @Date: 2020-11-04 15:47:08
 * @Module: 发布视频
 */
 <template>
  <div class="publishVideo-box">
    <div class="active">
      <div v-for="item in activeList"
           :key="item.key"
           :style="item.key==active?'color:#333;':'color: #999;'"
           @click="active=item.key"
           class="hover">{{item.title}}</div>
    </div>
    <div v-show="active =='publish'">
      <div class="publishVideo-content">
        <div v-show="fileList.length !==0"
             class="publishVideo-content-success">视频上传成功</div>
        <el-upload class="upload-demo"
                   :action="action"
                   :limit="1"
                   :on-remove="handleRemove"
                   :data="dataObj"
                   :on-preview="handlePreview"
                   :on-success="success"
                   :before-upload="upload">

          <div class="publishVideo-content-btn"
               v-show="fileList.length==0">上传视频</div>
        </el-upload>
        <div class="publishVideo-content-suggest"
             v-show="fileList.length==0">尺寸建议16:9（横图），分辨率不低于720PZ</div>
        <el-dialog visible
                   @close="dialogVisible=false"
                   v-if="dialogVisible">
          <video :src="action+path"
                 controls
                 style="width:100%">
          </video>
        </el-dialog>
      </div>
      <div class="publishVideo-form"
           v-show="fileList.length !==0">
        <el-form ref="formData"
                 label-width="100px"
                 :model="formData"
                 :rules="rules">
          <el-form-item label="标题:"
                        prop="title">
            <el-input v-model="formData.title"
                      maxlength="25"
                      show-word-limit></el-input>
          </el-form-item>
          <el-form-item label="封面:"
                        prop="cover">
            <from-cover v-model="formData.cover"
                        :videoaction="action"
                        :videopath="path" />
          </el-form-item>
          <el-form-item label="视频简介:"
                        prop="content">
            <el-input v-model="formData.content"
                      type="textarea"
                      :autosize="{ minRows: 4,maxRows:9 }"
                      maxlength="2000"
                      show-word-limit></el-input>
          </el-form-item>
          <el-form-item label="定时发送:"
                        v-show="administrator">
            <datePicker ref="datePicker"
                        style="margin:0px;" />
          </el-form-item>
        </el-form>
      </div>
      <div class="column-box">
        <el-form label-width="100px">
          <el-form-item label="原创设置:">
            <el-checkbox v-model="is_original">是否为原创</el-checkbox>
          </el-form-item>
          <el-form-item label="专栏设置:">
            <el-select v-model="column_id"
                       size="mini"
                       placeholder="请选择">
              <el-option v-for="item in columnListOption"
                         :key="item.id"
                         :label="item.title"
                         :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </div>
    </div>
    <add-column v-show="active =='column'"
                type="0"
                @onSuccess="columnSuccess" />

  </div>
</template>
 <script>
import fromCover from "./fromCover"
import addColumn from './addColumn'
import datePicker from "./datePicker"
import administrator from "@/mixins/administrator"
export default {
  components: {
    fromCover,
    addColumn,
    datePicker
  },
  data () {
    return {
      active: 'publish',
      activeList: [{
        title: '发布视频',
        key: 'publish'
      }, {
        title: '创建专栏',
        key: 'column'
      }],
      column_id: null,
      is_original: false,
      columnListOption: [],
      dataObj: {
        success_action_status: '200',
      },
      fileList: [],
      path: "",
      action: '',
      dialogVisible: false,
      formData: {
        title: '',
        content: '',
        cover: ''
      },
      rules: {
        title: [
          { required: true, message: '请输入标题', trigger: 'blur' },
        ],
        cover: [
          { required: true, message: '请选择封面', trigger: 'blur' },
        ]
      }
    };
  },
  mixins: [administrator],
  created () {
    this._getAliyun()
  },
  mounted () {
    this._get_columnListOption()
  },
  methods: {
    onPublish () {   //点击发布按钮
      console.log('发布视频')
      this.$refs.formData.validate((valid) => {
        if (valid) {
          this._set_data({
            ...this.formData,
            media: this.path,
            is_original: this.is_original,
            column_id: this.column_id
          })
        } else {
          return false;
        }
      });
    },
    // 获取专栏列表下拉菜单
    async _get_columnListOption () {
      const { status, data } = await this.$api.columnListOptionApi({ type: 0 })
      if (status == 200) {
        this.columnListOption = data
      }
    },
    async _getAliyun () {
      const { data: {
        policy,
        signature,
        accessid,
        host,
        dir
      } } = await this.$api.getAliyun({ type: 'video' })
      this.action = host
      Object.assign(this.dataObj, {
        policy,
        signature,
        dir,
        OSSAccessKeyId: accessid
      })
    },
    handleRemove (file, fileList) {  //文件列表移除文件时的钩子
      console.log(file, fileList)
      this.fileList = []
    },
    getFileName (filename) {    // 设置文件名字
      let getSuffix = (filename) => {
        let pos = filename.lastIndexOf('.');
        let suffix = '';
        if (pos != -1) {
          suffix = filename.substring(pos);
        }
        return suffix;
      };
      return (
        new Date().getTime() +
        Math.random()
          .toString(36)
          .substring(3, 20) +
        getSuffix(filename)
      );
    },
    upload (file) {   //文件上传前的回调
      console.log(file)
      console.log(file.type.split('/')[0])
      if (file.type.split('/')[0] !== 'video') {
        this.$message.error("请选择正确的格式。")
        return false
      }
      if (file.size > 1024 * 1024 * 500) {
        this.$message.error("视频最大支持五百兆。")
        return false
      }
      const filename = this.getFileName(file.name)
      const copyFile = new File([file], `${filename}`)
      Object.assign(this.dataObj, {
        key: `${this.dataObj.dir}${filename}`
      })
      this.path = `${this.dataObj.dir}${filename}`
      return copyFile
    },
    success (response, file, fileList) {   //上传成功
      console.log(response, file, fileList)
      this.formData.cover = this.path + "?x-oss-process=video/snapshot,t_0,f_jpg,m_fast"
      this.fileList = fileList
    },
    handlePreview () {
      this.dialogVisible = true
    },
    async _set_data (data) {   //请求发布
      if (this.administrator || this.$refs.datePicker.value) {
        data.published_at = this.$refs.datePicker.value
      }
      const { info, status } = await this.$api.publishVideoApi(data)
      if (status == 200) {
        this.$router.go(-1)
        this.$message.success(info)
      } else {
        this.$message.error(info)
      }
    },
    columnSuccess () {   //专栏创建成功     刷新专栏下拉列表  跳回发布页
      this._get_columnListOption()
      this.active = 'publish'
    }
  },
  watch: {
    active: {
      handler: function (val) {
        if (val == 'publish') {
          this.$emit('showBtn', true)
        } else {
          this.$emit('showBtn', false)
        }
      },
      // 深度观察监听
      deep: true,
      immediate: true
    }
  },
};
 </script>
 <style lang='scss' scoped>
.publishVideo-box {
  padding-bottom: 20px;
}
.active {
  display: flex;
  div {
    margin-right: 30px;
  }
}
.publishVideo-content {
  margin-top: 20px;
  height: 198px;
  background: #ffffff;
  box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  padding-top: 60px;
  padding-bottom: 41px;
  display: flex;
  flex-direction: column;
  align-items: center;
  &-btn {
    width: 140px;
    line-height: 40px;
    background: #df2a29;
    border-radius: 4px;
    color: #fff;
    text-align: center;
    margin: 0 auto;
    font-size: 14px;
  }
  &-suggest {
    text-align: center;
    margin-top: 27px;
    font-size: 14px;
    color: #666;
  }
  &-success {
    font-size: 20px;
    color: #df2a29;
  }
}
.publishVideo-form {
  margin-top: 16px;
  width: 880px;
  // height: 436px;
  background: #ffffff;
  box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  padding: 40px 150px 40px 50px;
}
.column-box {
  margin-top: 16px;
  height: 178px;
  background: #ffffff;
  box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  padding: 40px 50px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  &-item {
    display: flex;
    align-items: center;
    height: 28px;
    &-l {
      font-size: 16px;
      color: #333;
    }
    &-r {
      margin-left: 42px;
    }
  }
}
</style>